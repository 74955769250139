<template>
  <!-- Contents -->
  <div class="contents" id="contents">
    <div class="inner-sm">
      <!-- Event View Section -->
      <section class="board-view-section">
        <div class="board-view">
          <div class="view-header">
            <h5>{{eventInfo.name}}</h5>
            <div class="header-info">
              <ul>
                <li class="date">
                  <strong>
                    {{ getDateString(eventInfo.startDate, eventInfo.endDate) }}
                  </strong>
                </li>
                <!-- <template v-if="eventInfo.code_place_event !== 'EV209'">
                  <li class="v-bar">{{$t(`commonCode.${eventInfo.code_place_event}`)}}</li>
                </template> -->
                <template>
                  <li class="v-bar">{{eventInfo.placeName || eventInfo.placeDetail}}</li>
                </template>
                <li class="v-bar">{{getApplyUser(eventInfo.targets)}}</li>
              </ul>
              <ul class="writer">
                <li>{{eventInfo.adminName}}</li>
                <li class="v-bar">{{format.date(eventInfo.insertDate)}}</li>
              </ul>
            </div>
          </div>
          <div class="view-body">
            <!-- <div v-html="editorContent"></div> -->
            <BaseViewer :value="editorContent"></BaseViewer>
            <!-- 주의 사항 -->
            <div v-if="eventInfo.precaution || eventInfo.precaution2 || eventInfo.precaution3" class="precautions-section grey-box">
              <ul class="bullet-circle-list">
                <li v-if="eventInfo.precaution">{{eventInfo.precaution}}</li>
                <li v-if="eventInfo.precaution2">{{eventInfo.precaution2}}</li>
                <li v-if="eventInfo.precaution3">{{eventInfo.precaution3}}</li>
              </ul>
            </div>
            <!-- 주의 사항 -->
          </div>
        </div>
      </section>
      <!-- // Event View Section -->
      <!-- Paging -->
      <div class="paging-area board">
        <div class="btn-wrap">
          <a
            v-if="eventInfo.externalUrl"
            :href="eventInfo.externalUrl"
            target="_blank"
            title="새창"
          >
            <button
              class="btn-basic"
              :disabled="invaildedDate"
              :class="{ disabled: invaildedDate }"
            >{{invaildedDate ? $t('content.event.Event.txt14') : $t('content.event.Event.txt15')}}</button>
          </a>
          <button
            v-else
            @click="application"
            :disabled="applicated.invalided"
            :class="{ disabled: applicated.invalided,
              'btn-ghost': applicated.isBtnGhost,
              'btn-basic': !applicated.isBtnGhost }"
          >{{applicated.text}}</button>
        </div>
        <ul class="paging">
          <li>
            <a href="#" @click="historyBack" class="back">Back</a>
          </li>
          <li>
            <router-link
              :to="{ name: 'EventDetail'
                , params: { eventNo: eventInfo.prev }
                , query: { Date: this.eventDate} }"
              tag="a"
              class="prev"
              :class="{disabled: !eventInfo.prev}"
            >Prev</router-link>
          </li>
          <li class="v-bar">
            <router-link
              :to="{ name: 'EventDetail'
                , params: { eventNo: eventInfo.next}
                , query: { Date: this.eventDate} }"
              tag="a"
              class="next"
              :class="{disabled: !eventInfo.next}"
            >Next</router-link>
          </li>
        </ul>
      </div>
      <!-- Paging -->
    </div>
  </div>
  <!-- // CONTENTS -->
</template>

<script>

import { APIs } from '@/common/portalApi';
import { mixin as mixinHelperUtils, format } from '@/common/helperUtils';
import BaseViewer from '@/components/editor/BaseViewer.vue';
import _ from 'lodash';

export default {
  name: 'EventDetail',
  mixins: [mixinHelperUtils],
  components: {
    BaseViewer,
  },
  data() {
    return {
      format,
      editorContent: '',
      userInfo: undefined,
      applicated: {
        text: this.$t('content.event.Event.txt10'),
        invalided: false,
      },
    };
  },
  props: {
    /**
     * EventNo: 이벤트 항목번호
     */
    eventNo: {
      type: Number,
      required: true,
    },
    /**
     * EventInfo: 이벤트 항목의 상세정보
     */
    eventInfo: {
      type: Object,
      required: true,
    },
    /**
     * EventDate: 이벤트 항목의 달
     */
    eventDate: {
      type: [Number, String],
      required: true,
    },
  },
  created() {
    this.userInfo = this.$store.getters.getUserInfo();
  },
  computed: {
    preprecautions() {
      if (!this.eventInfo.preprecautions) {
        return [];
      }
      return this.eventInfo.preprecautions.split('|').filter((v) => v);
    },
    invaildedDate() {
      return (
        this.$moment().diff(
          this.$moment(this.eventInfo.applyEndDate),
          'days',
        ) > 0
      );
    },
    applyStatus() {
      if (!this.eventInfo.targets || !this.userInfo) {
        return false;
      }
      const users = this.eventInfo.targets.split('|');
      if (
        this.userInfo.member_role === 'MB298'
        || this.userInfo.member_role === 'MB299'
      ) {
        return true;
      }
      if (users.includes(`EV5${this.userInfo.member_role.substr(3)}`)) {
        return true;
      }
      return false;
    },
  },
  watch: {
    // 라우트가 변경되면 메소드를 다시 호출
    // https://router.vuejs.org/kr/guide/essentials/dynamic-matching.html
    // https://router.vuejs.org/kr/guide/advanced/data-fetching.html
    eventInfo: 'retrieveEvent',
  },
  beforeRouteUpdate(to, from, next) {
    const { name, params } = to;
    if (!(name === 'EventDetail' && !params.eventNo)) {
      next();
    }
  },
  methods: {
    getApplyUser(applyUserInfo) {
      if (!applyUserInfo) {
        return '';
      }
      const users = applyUserInfo.split(',') || [];
      return users.map((v)=>{
        if (v == 12) {
          return '입주사(강남)'
        } else if (v == 13) {
          return '일반회원'
        } else if (v == 14) {
          return '비회원'
        }
      }).join(', ');
    },
    retrieveEvent() {
      this.editorContent = this.eventInfo.description;
      this.refreshApplcation();
    },
    refreshApplcation() {
      this.applicated.invalided = false;
      this.applicated.text = this.$t('content.event.Event.txt10');

      if (this.eventInfo.applyEmail > 0) {
        this.applicated.text = this.$t('content.event.Event.txt24');
        this.applicated.invalided = false;
        this.applicated.isBtnGhost = true;
      }

      // 취소 여부 확인
      if (this.eventInfo.cancelDate != null) {
        this.applicated.text = this.$t('content.event.Event.txt25');
        this.applicated.invalided = true;
      }

      // 모집 일정 확인
      const today = this.$moment();
      const startDay = this.$moment(this.eventInfo.applyStartDate);
      const endDay = this.$moment(this.eventInfo.applyEndDate);
      const eventEndDay = this.$moment(this.eventInfo.endDate);

      // 모집 전
      if (today.diff(startDay, 'days') < 0) {
        this.applicated.text = this.$t('content.event.Event.txt19');
        this.applicated.invalided = true;
      }

      // 모집 일정 종료 또는 모집 인원 충족시
      // 이벤트 종료시
      if (
        today.diff(endDay, 'days') > 0 || 
        today.diff(eventEndDay, 'days') > 0 || 
        (this.eventInfo.maxMemberNumber - this.eventInfo.totalApplyCount) < 1
      ) {
        this.applicated.text = this.$t('content.event.Event.txt14');
        this.applicated.invalided = true;
      }

      this.$moment().diff(
        this.$moment(event.endDate),
        'days',
      ) > 0

      // 사용자 지원 대상 여부 확인
      const users = this.eventInfo.targets.split(',');
      // centerCode
      // 강남:12, 일반: 13, 비회원 14
      if ( 
        (this.userInfo && !this.userInfo.isAdmin && users.includes(`${this.userInfo.centerCode}`)) ||
        (!this.userInfo && (users.includes('14') || users.includes('13')))
      ) {
        
      } else {
        this.applicated.text = this.$t('content.event.Event.txt20');
        this.applicated.invalided = true;
      }
    },
    application() {
      if (this.userInfo) {
        /**
         * 이벤트 신청 내역이 있을 경우: 이벤트 신청서로 이동
         * 이벤트 신청 내역이 없을 경우: 이벤트 신청내역서로 이동
         */
        if (this.eventInfo.applyEmail > 0) {
          this.$router.push({
            name: 'EventHistory',
          });
        } else {
          this.portalApiClient({
            url: APIs.EVENT_APPLY_LIST,
            method: 'post',
            data: {
              data: {
                eventId: this.eventInfo.id,
                email: this.userInfo.email,
                memberId: this.userInfo.id,
                applyStatus: 1
              }
            }
          }).then(({ data }) => {
            if (data.result) {
              if (data.list.length > 0) {
                this.$router.push({
                  name: 'EventHistory',
                });
              } else {
                this.$router.push({
                  name: 'EventApply',
                });
              }
            } else {
              this.showAlert(data.message);
            }
          });
        }
        // if (this.eventInfo.event_detail.apply_info && this.eventInfo.event_detail.apply_info.event_apply_no) {
        //   this.$router.push({
        //     name: 'EventHistory',
        //   });
        // } else {
        //   this.$router.push({
        //     name: 'EventApply',
        //   });
        // }
      } else if (this.eventInfo.targets.includes(13) || this.eventInfo.targets.includes(14)) {
        // 취소 버튼
        const buttons = [{
            title: this.$t('content.event.Event.txt11'),
            type: 'line',
            class: '',
            handler: () => {
              this.$modal.hide('dialog');
            },
        }];
        if (this.eventInfo.targets.includes(13)) {
          // 로그인
          buttons.push({
            title: this.$t('content.event.Event.txt12'),
            default: true,
            class: '',
            handler: () => {
              this.$router.push({
                name: 'EventApply',
              });
            },
          });
        } else {
          // 로그인
          buttons.push({
            title: this.$t('content.event.Event.txt12'),
            default: true,
            class: '',
            handler: () => {
              this.$router.push({ name: 'SignIn',
                query: { redirectFrom: this.$route.fullPath}
              });
              // return next({
              //   name: routeTypes.ROUTE_NAME_SIGNIN,
              //   query: { redirectFrom: to.fullPath },
              // });   
            },
          });
        }
        
        // 비로그인
        if (this.eventInfo.targets.includes(14)) {
          buttons.push({
            title: this.$t('content.event.Event.txt13'),
            class: '',
            handler: () => {
              this.$router.push({
                name: 'EventApplyNonMember',
              });
            },
          });
        }
        this.showConfirm(this.$t('confirmMessage.requiredLogin'), {
          buttons: buttons
        });
      } else {
        this.showConfirm(this.$t('confirmMessage.requiredLogin'), () => {
          this.$router.push({
            name: 'EventApply',
            class: '',
            params: { eventInfo: this.eventInfo },
          });
        });
      }
    },
    historyBack(event) {
      event.preventDefault();
      console.log(this.eventInfo);
      this.$router.push({
        name: 'EventList',
        query: { Date: this.$moment(this.eventInfo.startDate.replace(/\./g, '-')).format('YYYYMM') },
      });
    },
    getDateString(startDate, endDate) {
      // 이벤트 기간 타이틀 문자열
      if (format.date(startDate) == format.date(endDate)) {
        return `${format.date(startDate)} ${format.time(this.$moment(startDate).format('HHmm'))} 
        - ${format.time(this.$moment(endDate).format('HHmm'))}`;
      } else {
        return `${format.date(startDate)} ${format.time(this.$moment(startDate).format('HHmm'))} 
        - ${format.date(endDate)} ${format.time(this.$moment(endDate).format('HHmm'))}`;
      }
    }
  },
};
</script>

<style>
.disabled,
.disabled:hover {
  color: #00000047 !important;
  cursor: default !important;
}
</style>
